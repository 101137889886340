if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function(){

    let form_id_3664 = document.querySelector( "#form_id-3705" )
    if( form_id_3664 ){

        let tokens = document.createElement( "input" )
        tokens.type = "hidden"
        tokens.name = "_access_token"

        form_id_3664.appendChild( tokens )
        form_id_3664.innerHTML += '<div class="hidden"> <label>Stay Golden</label> <input class="long" type="text" name="STAYGOLDEN" value="Enrolled"/> </div> '

        let checks = document.querySelectorAll( ".field_3705_21 label" )
        
        checks.forEach( ( item, index ) => {

            item.addEventListener( "click", ( e ) => {

                let token_of_list = "";

                let value_checkbox = e.target.parentNode.querySelector( "input" ).value

                switch (value_checkbox) {
        
                    case 'special_events_&_happenings':
                        token_of_list = '97168092-3538-415a-9b2d-f402aad12cba';
                        break;
                    case 'dining_&_culinary':
                        token_of_list = '8ae2b295-67ae-4a00-86d2-9e421cb20e87';
                        break;
                    case 'spa_terra_&_wellness':
                        token_of_list = '77703d6d-60ee-42b9-b3c4-8a722566c106';
                        break;
                    case 'meetings_&_events':
                        token_of_list = '7ada88b1-3eb4-4a6c-8682-d01576eb994c';
                        break;
                    case 'wedding_planning':
                        token_of_list = '57eab4f6-dbc6-4bc9-aa70-fe23159bf344';
                        break;
                    case 'stay_golden_rewards':
                        token_of_list = 'fb55968a-adf0-42a4-8234-2cea859eb577,2e5f923d-6782-436f-970f-5db55943f845'
                }

                let values = tokens.value

                if( values.indexOf( token_of_list ) != "-1" ){
                    values = values.replace( token_of_list + ",", "" )
                    tokens.value = values
                }else{
                    values += token_of_list + ","
                    tokens.value = values
                }

            } )

        } )

    }

    let form_id_3874 = document.querySelector( "input[name='form_id'][value='3874']" ); 
    if( form_id_3874 ){ 
        form_id_3874 = form_id_3874.parentElement; 

        let golden = document.createElement( "input" ) 
        golden.type = "hidden" 
        golden.name = "STAYGOLDEN" 
        golden.value = "Enrolled" 
        form_id_3874.appendChild( golden ) 
    }

    // Remove empty <p> tags created by tinymce
    const paragraph = document.querySelectorAll('p') || [];
    paragraph.forEach( e => (e.innerHTML.trim() === '') && e.parentNode.removeChild(e));

    let VideoHome = document.getElementById('myVideo');
    let VideoHeader = document.getElementById('videoHeader');

    if(VideoHome){
        VideoHome.oncanplaythrough = function() {
            VideoHome.muted = true;
            VideoHome.play();
        }
    }

    if(VideoHeader){
        VideoHeader = VideoHeader.getElementsByTagName('video')[0];
        VideoHeader.oncanplaythrough = function() {
            VideoHeader.muted = true;
            VideoHeader.play();
        }
    }


    $('.sym-slides').slick({
        accessibility: true
    });

    $('.images-slides').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        fade: true,
        accessibility: true
    });

    $('.images-slides-info').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,
        fade: true,
        accessibility: true
    });

    $('.reviews-slides').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        fade: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    adaptiveHeight: true,
                }
            }
        ]
    });

    $('.slider-info-slides').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 700,
        fade: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: true,
                    dots: false,
                    adaptiveHeight: true,
                }
            }
        ]
    });

    $('.slider-info').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 700,
        fade: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    adaptiveHeight: true,
                }
            }
        ]
    });

    $('.experience-slider').slick({
        arrows: true,
        dots: false,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 700,
        accessibility: true,
        adaptiveHeight: true,
    });

    $('.cards-slider__slider').slick({
        accessibility: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    autoplay: true,
                    autoplaySpeed: 10000,
                    dots: true,
                    slidesToShow: 1
                }
            },
        ]
    })

    let ScrollDown = document.getElementsByClassName('js-scrolldown');
    if(ScrollDown.length > 0){
        ScrollDown[0].addEventListener('click', function(){
            animateto('intro');
        });
    }

    //FAQ
    setTimeout(function(){
        $('.tab-label').each(function(){
            let thisHeightTap = $(this).height() + 44;
            let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
            $(this).parent().animate({height: thisHeightTap + 'px'},400);
            if($(this).hasClass('open')){
                $(this).parent().animate({height: thisHeight + 'px'},400);
            }
        });
    }, 400);
    
    

    $('#search').on('keyup',function(){
        const   searchText = $(this).val().toLowerCase(),
                items       = document.getElementsByClassName('accordion-item');

        if(searchText.length>2){
            for (let index = 0; index < items.length; index++) {
                items[index].classList.remove('hidden')
                const title = items[index].parentElement.getElementsByClassName('title-item')[0].innerText.toLowerCase();
                if(items[index].innerText.toLowerCase().indexOf(searchText)==-1){
                    items[index].classList.add('hidden')
                }
            }
        }else{
            for (let index = 0; index < items.length; index++) {
                items[index].classList.remove('hidden');
            }
        }
        setTimeout(()=>{
            $('.accordion-block').each(function(i,element){
                if($('.accordion-item:not(.hidden)',element).length==0){
                    $(element).addClass('hidden')
                }else{
                    $(element).removeClass('hidden')
                }
            })
        },0)
    })    

    $('.tab-label').click(function(){
        let currentclick = $(this);
        let thisHeightTap = $(this).height() + 44;

        let slideIndex = $(this).data('artwork');
        $(".accordion-slide").slick('slickGoTo', parseInt(slideIndex));

        if(!currentclick.hasClass('open')){
            $('.tab-label').each(function(){
                if($(this) !== currentclick ){
                    if($(this).hasClass('open')){
                        let thisHeight = $(this).height() + 44;
                        $(this).removeClass('open');
                        $(this).parent().animate({height: thisHeight + 'px'},400);
                    }
                }
            });
            currentclick.addClass('open');
            let thisHeight = $(this).next('.tab-content').height() + thisHeightTap;
            $(this).parent().animate({height: thisHeight + 'px'},400);
        } else {
            currentclick.removeClass('open');
            currentclick.parent().animate({height: thisHeightTap + 'px'},400);
        }
    });

    $('[data-fancybox]').fancybox({
        loop : true,
    });

    $('a[href^="#"]:not(:where(a[data-fancybox], a[data-filter], a.accordion-toggle, a#js_btn_book, a.js-more-images, a.btn-gallery))').click(function(e){
        e.preventDefault();
        let strAncla = $(this).prop('hash').substr(1);
        animateto(strAncla);
    });

    $("input[type=checkbox][value='The Meritage Resort and Spa']").prop("checked",true);
    $("input[name='zip_code']").attr('maxlength','5');

    //Function filter-list
    $('.filter-list__filter ul li').click(function(){
        const thisTag = $(this).attr('data-tag');
        $('.filter-list--filter .filter-list__item').hide();
        $('.filter-list--filter .'+thisTag).fadeIn();
        $('.filter-list--filter .'+thisTag).addClass('activeOn');
        $('.filter-list__filter ul li').removeClass('active');
        $(this).addClass('active');
    })

    if(document.querySelector('.filter-list')){
        const filter_tab = document.querySelectorAll('.filter-list__filter ul li'); filter_tab[1].click();
    }

    //email signup animation 
	if(document.getElementById('email-signup_email_address')){
		document.getElementById('email-signup_first_name').addEventListener('focus', function(){
			this.parentElement.classList.add('moved');
		})
        document.getElementById('email-signup_last_name').addEventListener('focus', function(){
			this.parentElement.classList.add('moved');
		})
        document.getElementById('email-signup_zip_code').addEventListener('focus', function(){
			this.parentElement.classList.add('moved');
		})
		document.getElementById('email-signup_email_address').addEventListener('focus', function(){
			this.parentElement.classList.add('moved');
		})

	}

    //for Specials
	document.querySelectorAll('.tabs_elements li').forEach((btn, i) => {
		btn.addEventListener('click', _ => {
			document.querySelectorAll('.tabs_elements li').forEach(b => b.classList.remove('active'));
			btn.classList.add('active');

			document.querySelectorAll('.specials-item').forEach(room => {
				room.classList.remove('active');
				if ( btn.dataset.tag === 'all' || btn.dataset.tag === room.dataset.tag ) {
					room.classList.add('active');
				}
			});
		});

		
		if (i === 0) btn.click();
	});

    let form_signup_lightbox = document.querySelector('#form-sign-up');
    if( form_signup_lightbox ){
        let allGroup = form_signup_lightbox.querySelectorAll('.form-module__group');
        let prev_group = document.querySelector('.js-back_group');
        let next_group = document.querySelector('.js-next_group');
        let div_progress_bar = document.querySelector('.js-progressbar');
        let stateNumberBar = 1;
        let stateProgressBar = 100 / allGroup.length;

        form_signup_lightbox.querySelector('.gdpr-wrap').classList.add('hidden');
        form_signup_lightbox.querySelector('.form-module__submit').classList.add('hidden');
        allGroup.forEach( ( eachGroup, i ) => {
            if( i > 0 ){
                eachGroup.classList.add('hidden');
            }
        } );

        div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;

        prev_group.addEventListener('click', (e) => {
            e.preventDefault();
            let currentGroupActive = form_signup_lightbox.querySelector('.form-module__group:not(.hidden)');
            let prevGroupActive = currentGroupActive.previousElementSibling;
            if( prevGroupActive.classList.contains('form-module__group') ){
                currentGroupActive.classList.add('hidden');
                prevGroupActive.classList.remove('hidden')
                stateNumberBar--;
                stateProgressBar = stateProgressBar - 100 / allGroup.length == 0 ? 25 : stateProgressBar - 100 / allGroup.length;
                div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;
                div_progress_bar.querySelector('progress').value = `${stateProgressBar}`;
                if( stateNumberBar == 1 ){
                    prev_group.classList.add('hidden')
                }

                if( stateNumberBar < allGroup.length ){
                    form_signup_lightbox.querySelector('.gdpr-wrap').classList.add('hidden');
                    form_signup_lightbox.querySelector('.form-module__submit').classList.add('hidden');
                    next_group.classList.remove('hidden');
                }
            }
        });

        next_group.addEventListener('click', (e) => {
            e.preventDefault();
            let currentGroupActive = form_signup_lightbox.querySelector('.form-module__group:not(.hidden)');
            let nextGroupActive = currentGroupActive.nextElementSibling;
            let conunterError = 0;

            currentGroupActive.querySelectorAll('input.required, textarea.required, select.required, checkbox.required').forEach( input => {
                if( input.value == '' || !input.value || input.value == ' ' ){
                    conunterError++;
                }
            } )

            if( conunterError == 0 ){
                if( nextGroupActive.classList.contains('form-module__group') ){
                    currentGroupActive.classList.add('hidden');
                    nextGroupActive.classList.remove('hidden');
                    stateNumberBar++;
                    stateProgressBar = stateProgressBar + 100 / allGroup.length;
                    div_progress_bar.querySelector('label').textContent = `${stateNumberBar}/${allGroup.length}`;
                    div_progress_bar.querySelector('progress').value = `${stateProgressBar}`;
                    if ( prev_group.classList.contains('hidden') ) {
                        prev_group.classList.remove('hidden');
                    }
                    if( document.querySelector('.fill_fields') ){
                        document.querySelector('.fill_fields').classList.add('hidden')
                    }
                    if( !nextGroupActive.nextElementSibling.classList.contains('form-module__group') ){
                        form_signup_lightbox.querySelector('.gdpr-wrap').classList.remove('hidden');
                        form_signup_lightbox.querySelector('.form-module__submit').classList.remove('hidden');
                        form_signup_lightbox.querySelector('.advice_to_requiered_fiels').classList.add('hidden');
                        next_group.classList.add('hidden');
                        
                    }
                }
            }else{
                if ( !document.querySelector('.fill_fields') ) {
                    form_signup_lightbox.insertAdjacentHTML( "beforeend", "<strong class='fill_fields'>Please fill all the fields Required *</strong>" );
                }
            }
            
        })
    }



    // ====== UPDATE thank You Message on sign-up form
    // const urlSignUpBTN = 'https://www.meritageresort.com/stay-golden';

    // function createAnchorButton( url ){
    //     let button = document.createElement('a');
    //     button.setAttribute('href', url);
    //     button.setAttribute('class', 'btn btn-primary');
    //     button.textContent = 'Join TODAY';

    //     return button;
    // }

    // console.log({urlSignUpBTN});

    // if(document.querySelector('.footer__signup')){
    //     let formMessage = document.querySelector('.footer__signup .thankyouMsg');

    //     let newMessage = "Thank you for signing up.</br> <span>You're Invited!</span> </br>Earn More Every Stay with Stay Golden Rewards at any Meritage Collection property. With this membership, you gain privileged access to exclusive pricing, rewards to enhance your stay, special offers and more. Make your stay count by signing up for our Stay Golden Rewards program and save more. </br>"


    //     formMessage.innerHTML = newMessage;
    //     formMessage.append( createAnchorButton( urlSignUpBTN ) );
    // }
    // END =====  UPDATE thank You Message on sign-up form

})();

function animateto(target){
    var gotoTarget = offset(document.getElementById(target)).top - 250;
    var from     = window.pageYOffset
    var to       = gotoTarget
    var duration = 1300;
    var start = new Date().getTime()

    //time interval
    var timer = setInterval(function() {
        var time = new Date().getTime() - start

        if(from <= to ){
            var y = easeInOutQuart(time, from, to - from, duration)
        } else {

            var y = easeInOutQuart(time, from, -(from - to), duration)
        }
        window.scrollTo(0, y)
        if (time >= duration) clearInterval(timer)

    }, 1000 / 60);

    window.scrollTo(0, from)

}
//ease movement
function easeInOutQuart(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
    return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
}
function offset(el) {
    try{
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }catch(e){
        return { top: 0, left: 0 }
    }
}

// Form Checkbox Hide Funcionality
const form = document.getElementById('form_id-2862');
if (form) {
    const input = form.querySelectorAll('.field.gdpr.clearfix');
    input[1].classList.add('hidden');
}
