// Func = limiting Scope for this file
var lastScrollTop = 0;

function slideNextContent(btn,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        $this.next().slideToggle();
    });
}
function showContent(btn,content,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        content.fadeToggle();
    });
}

$(function(){

	if( document.querySelector( "#form_id-2267" ) ){

		let contact_subject = document.querySelector( "[name=contact_subject]" );
		let contactForm = document.querySelector( "#form_id-2267" );

		contact_subject.addEventListener( "change", () => {

			switch( contact_subject.value ){
				case "restaurant_reservations":
					contactForm.querySelector( "[name=sendto]" ).value = "concierge@meritageresort.com";
					break;
				case "concierge_service":
					contactForm.querySelector( "[name=sendto]" ).value = "concierge@meritageresort.com";
					break;
				case "spa_terra":
					contactForm.querySelector( "[name=sendto]" ).value = "spaconcierge@meritageresort.com";
					break;
				case "marketing_public_relations":
					contactForm.querySelector( "[name=sendto]" ).value = "info@meritageresort.com";
					break;		
				case "group_sales_&_catering":
					contactForm.querySelector( "[name=sendto]" ).value = "sales@meritageresort.com";
					break;
				case "gift_cards":
					contactForm.querySelector( "[name=sendto]" ).value = "gift@meritageresort.com";
					break;
				case "billing_&_accounting":
					contactForm.querySelector( "[name=sendto]" ).value = "FrontOfficeManagement@meritageresort.com";
					break;
				default:
					contactForm.querySelector( "[name=sendto]" ).value = "reservations@meritageresort.com";
					break;
			}

		} )

	}

	scrollW();
	window.addEventListener('scroll', function(){
		scrollW();
	});

	$('input[type="date"]').on("input",function(){
		if($(this).val().length>0){
			$(this).addClass("full");
		}
		else{
			$(this).removeClass("full");
		}
	});

	$('.close-res').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');

		$('.booking-widget').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation
	$('#menu-btn').click(function(event) {
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({left:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	$('.close-menu').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	//SUBNAVIGATION

	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');

	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        }
    });

	let screenHeight = $(window).height();
	$('#home .top-img figure, #home .top-img video').css("height", screenHeight);
	
	$(window).resize(function(){
		screenHeight = $(window).height();
		$('#home .top-img figure, #home .top-img video').css("height", screenHeight);
	});

	let BookingBtn = document.getElementById('btn_book');
	let BookingBox = document.getElementById('bookingBox');
	let CloseBooking = document.getElementById('close_booking');

	let HotelMeritage = document.getElementById('hotel_meritage');
	let HotelGrand = document.getElementById('hotel_grand');

	let ContentHotel = document.getElementById('content_hotel');
	let ContentMeritage = document.getElementById('content_meritage');
	let ContentGrand = document.getElementById('content_grand');

	let ArrowOne = document.getElementById('arrow_one');
	let ArrowTwo = document.getElementById('arrow_two');

	if(BookingBox){
		if(BookingBtn){
			BookingBtn.addEventListener('click', function(e){
				e.preventDefault();
				BookingBox.classList.add('open');
				//Body.classList.add('scroll-no');
			});
		}

		// CloseBooking.addEventListener('click', function(e){
		// 	e.preventDefault();
		// 	BookingBox.classList.remove('open');
		// 	Body.classList.remove('scroll-no');
		// });

		// HotelMeritage.addEventListener('click', function(e){
		// 	e.preventDefault();
		// 	ContentHotel.classList.add('close');
		// 	ContentMeritage.classList.add('open');
		// });

		// ArrowOne.addEventListener('click', function(e){
		// 	e.preventDefault();
		// 	ContentHotel.classList.remove('close');
		// 	ContentMeritage.classList.remove('open'); 
		// });

		// HotelGrand.addEventListener('click', function(e){
		// 	e.preventDefault();
		// 	ContentHotel.classList.add('close');
		// 	ContentGrand.classList.add('open');
		// });

		// ArrowTwo.addEventListener('click', function(e){
		// 	e.preventDefault();
		// 	ContentHotel.classList.remove('close');
		// 	ContentGrand.classList.remove('open');
		// });

	}

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	//Prevents fancybox links from opening
	$("a.fancybox").on("click", function(e){
		e.preventDefault();
	});

	let BrtSub = document.getElementById('brtSub');
	let NavHotels = document.getElementById('navHotels');

	if(BrtSub){
		BrtSub.addEventListener('click', function(e){
			e.preventDefault();
			if(this.classList.contains("open")){
				this.classList.remove('open');
				NavHotels.classList.remove('open');
			} else {
				this.classList.add('open');
				NavHotels.classList.add('open');
			}
		});
	}



	$(".submenu__label, .sub-menu li").on("click", function(e){
		$(".sub-menu").slideToggle();
	});

	/*END OF CUSTOM SCRIPTS
	****************************************************************/
	function scrollW(){
		//check scroll down or up for header styles
		var st = window.pageYOffset || document.documentElement.scrollTop;
		
		if(st > 5){
			if (st > lastScrollTop){
				// downscroll code
				if(!document.getElementsByTagName('header')[0].classList.contains('down')){
					document.getElementsByTagName('header')[0].classList.add('down');
					document.getElementsByTagName('header')[0].classList.remove('up');

					document.getElementsByClassName('buttons')[0].classList.add('down');
					document.getElementsByClassName('buttons')[0].classList.remove('up');
				}
			} else {
				// upscroll code
				if(!document.getElementsByTagName('header')[0].classList.contains('up')){
					document.getElementsByTagName('header')[0].classList.add('up');
					document.getElementsByTagName('header')[0].classList.remove('down');

					document.getElementsByClassName('buttons')[0].classList.remove('down');
					document.getElementsByClassName('buttons')[0].classList.add('up');
				}
			}
		} else {
			if(document.getElementsByTagName('header')[0].classList.contains('up') || document.getElementsByTagName('header')[0].classList.contains('down')){
				document.getElementsByTagName('header')[0].classList.remove('up');
				document.getElementsByTagName('header')[0].classList.remove('down');

				document.getElementsByClassName('buttons')[0].classList.remove('down');
				document.getElementsByClassName('buttons')[0].classList.remove('up');
			}
		}
		lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
	}

    //Function filter-list
    $('.filter-list__filter ul li').click(function(){
        const thisTag = $(this).attr('data-tag');
        $('.filter-list--filter .filter-list__item').hide();
        $('.filter-list--filter .'+thisTag).fadeIn();
        $('.filter-list--filter .'+thisTag).addClass('activeOn');
        $('.filter-list__filter ul li').removeClass('active');
        $(this).addClass('active');
    })

    if(document.querySelector('.filter-list')){
        const filter_tab = document.querySelectorAll('.filter-list__filter ul li'); filter_tab[1].click();
    }

	//for Specials
	document.querySelectorAll('.tabs_elements li').forEach((btn, i) => {
		btn.addEventListener('click', _ => {
			document.querySelectorAll('.tabs_elements li').forEach(b => b.classList.remove('active'));
			btn.classList.add('active');

			document.querySelectorAll('.specials-item').forEach(room => {
				room.classList.remove('active');
				if ( btn.dataset.tag === 'all' || btn.dataset.tag === room.dataset.tag ) {
					room.classList.add('active');
				}
			});
		});

		
		if (i === 0) btn.click();
	});
	



	// ====== UPDATE thank You Message on sign-up form
	// const urlSignUpBTN = 'https://www.meritageresort.com/stay-golden';

	// function createAnchorButton( url ){
	// 		let button = document.createElement('a');
	// 		button.setAttribute('href', url);
	// 		button.setAttribute('class', 'btn');
	// 		button.textContent = 'Join TODAY';

	// 		console.lulp
	// }

	// if(document.querySelector('.footer .form-info .thankyouMsg')){
	// 		let formMessage = document.querySelector('.footer .form-info .thankyouMsg');

	// 		let newMessage = "Thank you for signing up.</br> <span>You're Invited!</span> </br>Earn More Every Stay with Stay Golden Rewards at any Meritage Collection property. With this membership, you gain privileged access to exclusive pricing, rewards to enhance your stay, special offers and more. Make your stay count by signing up for our Stay Golden Rewards program and save more. </br>";


	// 		formMessage.innerHTML = newMessage;
	// 		formMessage.append( createAnchorButton( urlSignUpBTN ) );
	// }
	// END =====  UPDATE thank You Message on sign-up form

});